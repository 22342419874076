import { getSystemInfoObjectProps } from '../../global';
import { isLowEnd, Platform } from './platform';

const getSystemProperties = async () => {
  return new Promise<Parameters<getSystemInfoObjectProps['onComplete']>[0]>(
    (resolve, reject) => {
      setTimeout(() => {
        reject({
          returnValue: false,
          errorCode: 'ERROR_00',
          errorText: 'No response from TV within 1.5 seconds'
        });
      }, 1500);

      const _variable = window.webOS?.service.request(
        'luna://com.webos.service.tv.systemproperty',
        {
          method: 'getSystemInfo',
          parameters: {
            keys: [
              'boardType',
              'modelName',
              'firmwareVersion',
              'UHD',
              'sdkVersion'
            ]
          },
          onComplete: (result) => {
            if (result.returnValue !== true) {
              reject(result);
              return;
            }
            resolve(result);
          }
        }
      );
    }
  );
};

// The Version from LUNA is said to be inconsistent, so we use the app version on v3.x
const getWebOs3Version = async () => {
  try {
    if (webOsLunaVersion === undefined) {
      const info = await getSystemProperties();
      if (info.returnValue === true) {
        const modelName = info.modelName;
        // Regex find version from WEBOS3.5, WEBOS4.0, etc
        const version = modelName.match(/WEBOS([\d.]+)/);
        if (version && version[1]) {
          const versionNumber = parseFloat(version[1]);
          if (
            !isNaN(versionNumber) &&
            versionNumber > 0 &&
            versionNumber < 30
          ) {
            webOsLunaVersion = versionNumber;
          }
        }
      }
    }
  } catch (error) {
    webOsLunaVersion = null;
  }
  // Just a sanity check to make sure we're on a 3.x version

  if (webOsLunaVersion && webOsLunaVersion >= 3 && webOsLunaVersion < 4) {
    return webOsLunaVersion;
  }

  return 3;
};

/**
 * WebOS has 3.x, 4.x versions, etc. But, we have no reliable way to detect
 * those, so we consider only the major version number when detecting versions,
 * even if technically the TV could be on 3.0 or 3.5 when we detect it's on 3.
 */
let webOsLunaVersion: number | undefined | null = undefined;

export const getExactWebosVersion = async (): Promise<number | null> => {
  const approximateVersion = getApproximateWebosVersion();
  if (approximateVersion === 3) {
    return await getWebOs3Version();
  }
  return approximateVersion;
};

// Use the approximate version number when we MUST read the version non async
//  and exact WebOS version doesn't matter
export const getApproximateWebosVersion = (): number | null => {
  const ua = navigator.userAgent;

  // Browser user agents is listed here:
  // https://webostv.developer.lge.com/develop/specifications/web-api-and-web-engine
  if (ua.search(/Web0S/i) > -1) {
    if (ua.search(/Chrome\/108.0.5359.211/i) > -1) {
      return 24;
    } else if (ua.search(/Chrome\/94.0.4606.128/i) > -1) {
      return 23;
    } else if (ua.search(/Chrome\/87.0.4280.88/i) > -1) {
      return 22;
    } else if (ua.search(/Chrome\/79.0.3945.79/i) > -1) {
      return 6;
    } else if (ua.search(/Chrome\/68.0.3440.106/i) > -1) {
      return 5;
    } else if (ua.search(/Chrome\/53.0.2785.34/i) > -1) {
      return 4;
    } else if (ua.search(/Chrome\/38.0.2125.122/i) > -1) {
      return 3;
    } else if (ua.search(/Safari\/538.2/i) > -1) {
      return 2;
    } else if (ua.search(/Safari\/537.41/i) > -1) {
      return 1;
    }
  }

  return null;
};

export const getDrmMessage = (licenseServer: string, jwt: string): string => {
  const drmMsg =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<PlayReadyInitiator xmlns="http://schemas.microsoft.com/DRM/2007/03/protocols/">' +
    '<LicenseServerUriOverride>' +
    '<LA_URL>' +
    licenseServer +
    '</LA_URL>' +
    '</LicenseServerUriOverride>' +
    '<SetCustomData>' +
    '<CustomData>' +
    jwt +
    '</CustomData>' +
    '</SetCustomData>' +
    '</PlayReadyInitiator>';
  return drmMsg;
};

export const createMediaOptions = (
  startTimeSec: number,
  drmClientId: string
) => {
  //htmlMediaOption is needed to access LGs internal audio API
  const options = {
    option: {
      drm: {
        type: 'playready',
        clientId: drmClientId
      },
      transmission: {
        playtime: {
          start: Math.floor(startTimeSec * 1000)
        }
      }
    },
    htmlMediaOption: {
      useUMSMediaInfo: true
    }
  };

  const mediaOption = escape(JSON.stringify(options));

  return mediaOption;
};

export const shouldUseWebosNativePlayer = () => {
  return Platform.lg() && isLowEnd;
};
